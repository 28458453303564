import axios from 'axios';
import classnames from 'classnames';
import React, { useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {EMAIL_REGEX, INVALID_EMAIL, INVALID_PHONE, PHONE_REGEX, REQUIRED_MESSAGE} from '../../utils/regex';
import AlertPopup from '../alert-popup/AlertPopup';
import Button from '../button/Button';
import Input from '../input/Input';
import Textarea from '../textarea/Textarea';
import * as styles from './contact-us-form.module.scss';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type ContactFormFields = {
    name: string,
    email: string,
    phone: string,
    message: string
}

type ContactUsFormProps = {
    className?: string,
    messageRows?: number,
    setIsFetching: (value: boolean) => void,
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({ className, messageRows, setIsFetching }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: ''
        },
        mode: 'all',
    });
    const { errors } = useFormState({ control });
    const [isSubmitRes, setIsSubmitRes] = useState<string>('');
   
    const onSubmit = async (values: ContactFormFields) => {
        if (!executeRecaptcha) {
            return
        }    
        setIsFetching(true);  
        const result = await executeRecaptcha('homepage');
        
        try {
            const res = await axios.post(
                `${process.env.GATSBY_CONTACT_US}`, 
                {...values, captcha: result}, 
                {}
            );
            if(res.data.code === 0) {
                reset();
            }
            setIsSubmitRes(res.data.message);
            setIsFetching(false);  
        } catch (error: any) {
            setIsSubmitRes('Ooops! Something went wrong ;( Please, try again later');
            console.debug(error.message);
            setIsFetching(false);  
        }
    };

    return (
        <>
            {isSubmitRes && <AlertPopup
                message={isSubmitRes}
                closePopup={() => setIsSubmitRes('')}
            />}
            <form name="contact-form" className={classnames(className, styles.contactUsForm)} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.contactUsFormHeaderInputs}>
                    <Controller
                        name={'name'}
                        control={control}
                        rules={{
                            required: REQUIRED_MESSAGE,
                            maxLength: {
                                value: 100,
                                message: 'Entered value should have max 100 characters',
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                label={"First Name"}
                                placeholder={'Steave'}
                                className={styles.topInput}
                                onChange={field.onChange}
                                value={field.value}
                                error={errors["name"]?.message}
                            />
                        )}
                    />
                    <Controller
                        name={'phone'}
                        control={control}
                        rules={{
                            required: REQUIRED_MESSAGE,
                            pattern: {
                                value: PHONE_REGEX,
                                message: INVALID_PHONE,
                            },
                            maxLength: {
                                value: 30,
                                message: 'Entered value should have max 30 characters',
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                label={"Phone No"}
                                placeholder={'123 456 7890'}
                                className={styles.topInput}
                                onChange={field.onChange}
                                value={field.value}
                                error={errors["phone"]?.message}
                            />
                        )}
                    />
                </div>
                <Controller
                    name={'email'}
                    control={control}
                    rules={{
                        required: REQUIRED_MESSAGE,
                        pattern: {
                            value: EMAIL_REGEX,
                            message: INVALID_EMAIL
                        }
                    }}
                    render={({ field }) => (
                        <Input
                            label={"Email Address"}
                            placeholder={'your_email@email.com'}
                            className={styles.contactUsFormInput}
                            onChange={field.onChange}
                            value={field.value}
                            error={errors["email"]?.message}
                        />
                    )}
                />
                <Controller
                    name={'message'}
                    control={control}
                    rules={{
                        maxLength: {
                            value: 2000,
                            message: 'Entered value should have max 2000 characters',
                        },
                    }}
                    render={({ field }) => (
                        <Textarea
                            name={'message'}
                            label={'Message'}
                            placeholder={'Type Your Message Here!'}
                            rows={messageRows}
                            value={field.value}
                            onChange={field.onChange}
                            error={errors["message"]?.message}
                        />
                    )}
                />
                <Button
                    name='SEND MESSAGE'
                    type='submit'
                    className={styles.contactUsFormButton}
                />
            </form>
        </>
    );
};

export default ContactUsForm;