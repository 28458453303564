import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { useState } from 'react';
import ContactItem from '../components/contact-item/ContactItem';
import ContactUsForm from '../components/contact-us-form/ContactUsForm';
import Layout from '../components/layout/Layout';
import Spinner from '../components/spinner/Spinner';
import TitleH2 from '../components/title-h2/TitleH2';
import * as styles from '../pages/contact-us/contact-us.module.scss';
import { ContactUsFrontmatter, IContactUsPageData } from '../types/contactUsTypes';
import { Fade, Slide } from 'react-reveal';

type ContactUsTemplateProps = {
    frontmatter: ContactUsFrontmatter,
    setIsFetching: (value: boolean) => void
}

export const ContactUsPageTemplate: React.FC<ContactUsTemplateProps> = ({ frontmatter, setIsFetching }) => {
    const { title, subtitle, background, rhombusImage, heading, contacts, contactsBackground } = frontmatter;
    return (
        <main>
            <section className={styles.main} style={{ backgroundImage: `url(${background})` }}>
                <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
                    <Fade delay={500}>
                        <h1 className={styles.mainTitle}>{title}</h1>
                    </Fade>

                    <Fade delay={500}>
                        <p className={styles.mainSubtitle}>
                            {subtitle}
                        </p>
                    </Fade>

                    <img className={styles.mainImage} src={rhombusImage} alt="Main sectiom" />
                </div>
            </section>

            <section className={styles.contact} style={{ backgroundImage: `url(${contactsBackground})` }}>
                <div className={classNames(classNames, styles.contactContainer, 'wrapper')}>
                    <Slide right>
                        <TitleH2 className={styles.contactTitle}>{heading}</TitleH2>
                    </Slide>

                    <Slide right>
                        <div className={styles.contactSocials}>
                            {contacts?.map((item, i) => <ContactItem key={i} contact={item} />)}
                        </div>
                    </Slide>

                    <ContactUsForm
                        className={styles.contactForm}
                        setIsFetching={setIsFetching}
                    />
                </div>
            </section>
        </main>
    );
};

type ContactUsContainerProps = {
    data: IContactUsPageData
}

const ContactUsContainer: React.FC<ContactUsContainerProps> = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const [isFetching, setIsFetching] = useState<boolean>(false);

    return (
        <Layout>
            {isFetching && <Spinner />}
            <ContactUsPageTemplate
                frontmatter={frontmatter}
                setIsFetching={setIsFetching}
            />
        </Layout>
    );
};

export const contactUsPageQuery = graphql`
  query ContactUsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        templateKey
        heading
        background
        rhombusImage
        path
        contactsBackground
        contacts {
          icon
          info
          title
        }
      }
    }
  }
`;

export default ContactUsContainer;

