import React from 'react';
import { ContactType } from '../../types/contactUsTypes';
import * as styles from './contact-item.module.scss';

type ContactItemProps = {
    contact: ContactType
}

const ContactItem: React.FC<ContactItemProps> = ({ contact }) => {
    
    const infoRenderer = () => {
        if(contact.title.toLowerCase() === 'location') {
            return <p className={styles.contactItemSectionInfo}>{contact.info}</p>;
        } else {
            if(contact.title.toLowerCase() === 'email') {
                return <a href={`mailto: ${contact.info}`} className={styles.contactItemSectionInfo}>{contact.info}</a>;
            } else {
                return <a href={`tel: ${contact.info}`} className={styles.contactItemSectionInfo}>{contact.info}</a>;
            }
        }
    };
    
    return (
        <div className={styles.contactItem}>
            <img src={contact.icon} alt="" className={styles.contactItemIcon} />
            <div className={styles.contactItemSection}>
                <h3 className={styles.contactItemSectionTitle}>{contact.title}</h3>
                {infoRenderer()}
            </div>
        </div>
    );
};

export default ContactItem;