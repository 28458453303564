// extracted by mini-css-extract-plugin
export var contact = "contact-us-module--contact--EGzN8";
export var contactContainer = "contact-us-module--contact-container--mCnZl";
export var contactForm = "contact-us-module--contact-form--eQs4B";
export var contactSocials = "contact-us-module--contact-socials--yB1jB";
export var contactTitle = "contact-us-module--contact-title--TMkgX";
export var contactUsPage = "contact-us-module--contact-us-page--WiPxp";
export var contactUsPageFormSection = "contact-us-module--contact-us-page-form-section--29w2K";
export var contactUsPageMain = "contact-us-module--contact-us-page-main--ob0tn";
export var contactUsPageMainImage = "contact-us-module--contact-us-page-main-image--DVdvH";
export var contactUsPageMainTitle = "contact-us-module--contact-us-page-main-title--QIsos";
export var contacts = "contact-us-module--contacts--DUcY0";
export var contactsContent = "contact-us-module--contacts-content--+2OxQ";
export var contactsHeading = "contact-us-module--contacts-heading--Gezgd";
export var main = "contact-us-module--main--WZ8q+";
export var mainContainer = "contact-us-module--main-container--Qe-H7";
export var mainImage = "contact-us-module--main-image--Y7e9G";
export var mainSubtitle = "contact-us-module--main-subtitle--Z9mBh";
export var mainTitle = "contact-us-module--main-title--N7bP1";